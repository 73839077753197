.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-image {
/*   background-image: url("./images/rontutorial.png");
  background-image: url("./images/rontutorial.png"); */
  background-image: url("./images/ron1d.JPG");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
}

img {
  vertical-align: middle;
  }

.mid-image{
  position: absolute;
  top: -50%;
  justify-content: center;
  text-align: center;

}



.img-center {
  position: absolute;
  top: 0%;
  z-index: 100;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
/* this will center the image and align it to the top */
.img-center1{
margin: 0;
position: absolute;
top: 0%;
left: 50%;
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}


figure {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}


.fuck{
  /* position: absolute; */
  width: 100%;
  background-color: red;
  top: -50%;
  height: 200px;

}

.boxhw{
  width: 400px;

}

/* card.js */

.fixed{
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
}


.fixedCenter{

width: 500px;
height: 50%;

margin-top: -100px; /* Negative half of height. */
margin-left: -250px; /* Negative half of width. */
/* background: #c5d7e0; */
background: #ffffff;
/* background-color: #ffffff;
opacity: .4; */


}

 .same{
  width: 500px;
  height: 100%;
  padding-top: 10 px;
  padding-bottom: 10px;
} 

/* .same{
  background-color: white;
} */

.vertical-center {
  margin: 0;
  padding-top: 10 px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.backg{
  background: #c5d7e0;
}


.showContact{
  display:none;
}

/* footer */

.contactFooter{
  position: fixed;
  right: 0px;
}

/* navBar */

.fixedTop{
  position: fixed;
  top: 0%;
}

/* any components */

.hide{
display: none;
}

.align-centered{
  text-align: center;
}
.is-relative{
  position: relative;
}

.abs{
  position: absolute;

  }

  .hrPad{
    padding: 5px;
  }

  .hrPad2{
    padding-top: 2px;
  }

  .openSans{
    font-family: 'Open Sans', sans-serif;
    
  }

  .roboto{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
  }

  .underline{
    text-decoration: underline;
  }

  .cardsize{
    width: 300px;
    height: 200px;
  }

